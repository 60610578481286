@import "./mixins.scss";

.button {
  min-width: 10rem;
  padding: 1rem 1.5rem;
  cursor: pointer;
  background-color: rgb(92, 202, 245);
  border-radius: 4px;
  border: none;
  outline: none;
  text-transform: capitalize;
  @include screen("inbetween") {
    min-width: 0;
  }

  a {
    color: #fff;
  }
  &.loading {
    cursor: default;
    pointer-events: none;
  }

  &.disable {
    opacity: 50%;
    cursor: default;
    pointer-events: none;
  }

  &.big-blue-btn {
    // max-width: 23rem;
    width: 75%;
    height: 3rem;
    background-color: #0d3dde;
    border-radius: 47px;
    color: #ffffff;
    font-size: 1.1rem;
    font-weight: normal;
    margin-bottom: 2rem;
    padding: 0;
    @include screen("inbetween") {
      width: 100%;
      height: 2.8rem;
      font-size: 0.8rem;
      margin-bottom: 0.8rem;
    }
  }
  &.big-white-btn {
    // max-width: 23rem;
    width: 75%;
    height: 3rem;
    background-color: white;
    border: 1px solid #0d3dde;
    border-radius: 47px;
    color: #0d3dde;
    font-size: 1.1rem;
    font-weight: normal;
    padding: 0;
    @include screen("inbetween") {
      width: 100%;
      height: 2.8rem;
      font-size: 0.8rem;
    }
  }
  &.small-white-btn {
    width: 10rem;
    // height: 3.3rem;
    height: 1.4rem;
    background-color: white;
    border: 1px solid #0d3dde;
    border-radius: 47px;
    color: #0d3dde;
    font-size: 1.3rem;
    font-weight: normal;
    padding: 0;
    @include screen("inbetween") {
      width: 45%;
      height: 100%;
      font-size: 0.8rem;
    }
  }
  &.small-blue-btn {
    width: 12rem;
    // height: 3.3rem;
    height: 4rem;
    background-color: #0d3dde;
    border: 1px solid #0d3dde;
    border-radius: 47px;
    color: white;
    font-size: 1.3rem;
    font-weight: normal;
    padding: 0;
    @include screen("inbetween") {
      width: 45%;
      height: 100%;
      font-size: 0.8rem;
    }
  }
  &.webcam-capture-btn {
    width: 40%;
    min-width: 0;
    // width: 5rem;
    height: 2.5rem;
    padding: 0 0.3rem;
    background-color: #0d3dde;
    border: 1px solid #0d3dde;
    color: white;
    // margin-right: 1rem;
    @include screen("inbetween") {
    }
  }
  &.webcam-delete-btn {
    min-width: 0;
    background-color: white;
    border: 1px solid #0d3dde;
    width: 40%;
    height: 2.5rem;
    padding: 0 0.3rem;
  }
  &.create-btn{
    // margin-top: 4rem;
    background-color: #23BAA6;
    color: white;
    font-weight: bold;
  }

  &.delete-btn {
    min-width: 7rem;
    background: #fe753e;
    color: #fff;
    padding: 10px 0;
    display: block;
  }
}
#small-btn-left {
  margin-right: 1.5rem;
}

#bell {
  width: 2rem;
  height: 2rem;
}
