// @import "./mixins.scss";
// @import "./screens.scss";

footer {
  background-color: #030c2b;
  height: 8rem;
  width: 100%;
  z-index: 99;
  @include flex(column, nill, center);
  color: #ffffffc4;
  padding-top: 1rem;
  @include screen("inbetween") {
    height: 6rem;
  }
  .socials {
    @include flex(row, space-around, center);
    list-style: none;
    width: 50%;
    @include screen("inbetween") {
      width: 80%;
    }
    li {
      a {
        color: #ffffffc4;
        text-decoration: none;
        margin: 0;
        cursor: pointer;
        padding: 10px;
        @include screen("inbetween") {
          font-size: 0.625rem;
        }
      }
    }
  }
  p {
    margin: 2rem 0 0 0;
    font-size: 0.8rem;
    @include screen("inbetween") {
      font-size: 0.5rem;
      margin-top: 1.5rem;
    }
  }
}
