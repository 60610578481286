.advert-section {
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    color: white;
    height: 8.5rem;
    margin-bottom: 2rem;
    @include screen("inbetween") {
      height: 5rem;
      margin-bottom: 5rem;
    }
    .advert-box {
      height: 85%;
      background-color: #041345;
      box-sizing: border-box;
      @include flex(row, space-between, center);
      padding: 0 8% 0 4%;
      // margin: 0 2%;
      border-radius: 5px;
      @include screen("inbetween") {
        padding: 0 5% 0 2%;
      }
      .left {
        width: 50%;
        height: 70%;
        @include flex(row, nill, center);
        @include screen("inbetween") {
          width: 70%;
          height: 50%;
        }
        .img-box {
          background-color: #f8fbff;
          min-height: 4rem;
          min-width: 4rem;
          padding: 5px;
          margin-right: 15%;
          border-radius: 5px;
          @include screen("inbetween") {
            background-color: #041345;
            height: auto;
            margin-right: 8%;
          }
          .img {
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 5px;
            height: 4rem;
            width: 4rem;
          }
        }
        .description {
          @include flex(column, nill, nill);
          height: 100%;
          box-sizing: border-box;
          h5 {
            margin: 0;
            margin-bottom: 1.5rem;
            font-size: 1rem;
            font-weight: bold;
            @include screen("inbetween") {
              font-size: 0.7rem;
              justify-content: center;
              align-items: center;
            }
          }
          p {
            margin: 0;
            font-size: 0.8rem;
            display: block;
            width: 300px;
            @include screen("inbetween") {
              font-size: 0.6rem;
            }
          }
        }
      }
      .btn-box {
        width: 15%;
        border-radius: 5px;
        background-color: #fff;
        color: #0d3dde;
        font-size: .8rem;
        white-space: nowrap;
        @include screen("inbetween") {
          width: 23%;
        }

        .join-btn {
          width: 100%;
          padding: 1rem 1.5rem;
          border-radius: 5px;
          border: none;
          background-color: #fff;
          color: #0d3dde;
          font-size: .8rem;
          white-space: nowrap;
          cursor: pointer;
          @include screen("inbetween") {
            font-size: 0.6rem;
            padding: 0.7rem 0.6rem;
            border-radius: 2px;
          }
          
          button{
            padding: 1rem 1.5rem;
            cursor: pointer;
            border: none;
            outline: none;
          }

        }
      }
    }
    #here {
      margin: 0.2rem 2.5% 0 0;
      text-align: right;
      a {
        color: black;
        font-size: 0.8rem;
        color: #7a7a7e;
        @include screen("inbetween") {
          font-size: 0.6rem;
        }
      }
    }
  }


 