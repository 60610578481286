@import "./mixins.scss";

.verifications-container {
	box-sizing: border-box;
	.back-btn {
		background: none;
		outline: none;
		border: none;
		font-size: 1rem;
		cursor: pointer;
		display: flex;
		align-items: center;
		color: #423B3B;
		svg{
			margin-right: 10px;
		}
	}
	.header {
		padding: 0;
		box-sizing: border-box;
		margin-top: 25px;
		@include flex(row, space-between, center);

		h3 {
			margin: 0;
			padding: 0;
			font-size: 22px;
			font-weight: bold;
			color: #010b1a;
		}

		.verification-status-cont {
			@include flex(row, space-between, center);
			.icon {
				svg {
					width: 1.5rem;
					height: 1.5rem;
					padding-right: 0.5rem;
				}
			}

			.verification-status {
				font-size: 0.9rem;
				font-weight: 300;
				opacity: 1;
				text-transform: capitalize;
			}
			background-color: #f8fbff;
			padding: 10px 30px;
			border-radius: 5px;
			.pending-status {
				@extend .verification-status;
				color: #ffc144;
			}
			.verified-status {
				@extend .verification-status;
				color: #23baa6;
			}
			.revoked-status {
				@extend .verification-status;
				color: #fe753e;
			}
			.rejected-status{
				@extend .verification-status;
				color: #423b3b;
			}
		}
	}

	.profile-cont {
		margin-top: 60px;
		box-sizing: border-box;
		@include flex(row, space-between, nill);
		gap: 20px;
		border-bottom: 1px solid #eee9e9;
		padding-bottom: 2.5rem;
		margin-bottom: 2.5rem;

		.left, .right{
			display: flex;
			flex-wrap: wrap;
			padding: 0 10px;
		}
		.left {
			width: 45%;
			min-width: 420px;
		}
		.right{
			width: 55%;
		}

		.left, .right {
			display: flex;
			justify-content: space-between;
			.image-cont {
				flex: 1;
				min-height: 140px;
				margin-right: 15px;
				img{
					border-radius: 5px;
					background: #fff;
				}
				&.last{
					width: 20%;
					min-height: 50px;
					height: 80px;
					margin-left: 10px;

					&:hover{
						.username{
							color: #0d3dde;	
							text-decoration: underline;
						}
					}

					.small-img{
						height: 5rem;
						width: 5rem;
					}
					
					.username{
						margin: 0;
						line-height: 1.3;
					}
				}

				h5{
					margin: 0;
					color: #423b3b;
					opacity: 1;
					font-size: 0.85rem;
					font-weight: bold;
					margin-bottom: 1.1rem;
				}	
				.name {
					display: block;
					font-size: 0.85rem;
					color: #423b3b;
					opacity: 1;
					text-decoration: none;
					word-break: break-all;
				}
					
				.occupation {
					padding: 0.8rem 0;
				}
	
				.skills {
					margin: 0;
				}

				.details{
					@include flex(row, center, center);
					cursor: pointer;
					margin-top: 20px;
					img{
						min-height: 20px;
						width: 1.5rem;
						height: 1.5rem;
						border-radius: 0;
						margin-right: 0.5rem;
					}
					span{
						font-size: 0.6rem;
					}
				}
			}
			.text-cont{
				margin-right: 15px;
				h5{
					margin: 0;
					color: #423b3b;
					opacity: 1;
					font-size: 0.85rem;
					font-weight: bold;
					// line-height: 0;
				}	
				.editable-field{
					margin-bottom: 1.1rem;
				}
				.name {
					display: block;
					font-size: 0.85rem;
					color: #423b3b;
					opacity: 1;
					text-decoration: none;
					word-break: break-all;
					margin-bottom: 1.1rem;
				}
					
				// .occupation {
				// 	padding: 0.8rem 0;
				// }
	
				// .skills {
				// 	margin: 0;
				// }
			}
		}

	}

	.ruler {
		margin: 2rem 0;
		border: 1px solid rgb(238, 233, 233);
		opacity: 1;
	}

	.social-cont {
		@include flex(row, nill, null);
		max-width: 100%;
		flex-wrap: wrap;
		.social {
			width: 20%;
			max-width: 200px;
			padding-right: 10px;
			margin-bottom: 10px;			
			h5 {
				margin: 0;
				color: #423b3b;
				margin-bottom: 1.3rem;
				font-size: .9rem;
				text-transform: capitalize;
			}

			.social-link {
				width: 100%;
				padding: 8px 0;
				background: none;
				text-decoration: none;
				color: #423b3b;
				font-size: .8rem;
				word-wrap: break-word;
				&:disabled{
					color: #0d3dde;
					border: none;
				}
			}
		}
	}

	.buttons-cont {
		padding-top: 6rem;
		width: 70%;
		@include flex(row, flex-start, null);
		gap: 70px;

		.btn-text {
			color: white;
			font-weight: 600;
		}

		.verify {
			@extend .btn-text;
			background-color: #23baa6;
		}
		.reverify {
			@extend .btn-text;
			background-color: #23baa6;
		}
		.reject {
			@extend .btn-text;
			background-color: #fe753e;
		}
		.save {
			@extend .btn-text;
			background-color: #0d3dde;
		}
	}
}
