.filter{
    @include flex(column, nill, flex-start);
    width: 45%;
    // border: 1px solid red;
    .label{
        font-size: 0.9rem;
        margin-bottom: 0.5rem;
        font-weight: 800;
        color: #423b3b;
    }
    select{
        width: 75%;
        max-width: 120px;
        padding: 5px 20px;
        height: 3.5rem;
        border-radius: 10px;
        background: transparent;
        font-style: normal;
        display: block;
        -moz-padding-start: calc(0.75rem - 3px);
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        &:hover {
            border: 1px solid #0d3dde;
        }
    }
}