.sidebar {
  width: 100%;
  min-height: 100%;
  padding-top: 50px;
  padding-left: 15px;
  @include flex(column, null, null);
  position: relative;
  box-sizing: border-box;
  overflow-x: hidden;
  background: #ffffff;
  @media screen and (max-width: 1200px) {
    padding-left: 0;
  }
  .top {
    width: 100%;
    height: 20%;
    @include flex(row, null, center);
    border-bottom: 0.5px solid rgba(205, 205, 205, 0.5);
    margin-bottom: 3rem;
    padding: 0 3% 1rem 10%;
    box-sizing: border-box;
    text-align: center;
    #logo {
      cursor: pointer;
      height: 80%;
      width: 60px;
      margin-right: 1rem;
    }
    .top-right {
      @include flex(column, flex-start, flex-start);
      height: 30%;

      #title {
        color: #010b1a;
        font-weight: bold;
        font-size: 1.2rem;
        margin: 0;
        text-transform: capitalize;
        line-height: 0.9;

        @media screen and (max-width: 1200px) {
          font-size: 0.9rem;
        }
      }
      #name {
        color: #707070;
        font-weight: normal;
        font-size: 1.2rem;
        margin: 10px 0 0 0;
        text-transform: capitalize;
        line-height: 0.9;

        @media screen and (max-width: 1200px) {
          font-size: 0.9rem;
        }
      }
    }
  }
  .down {
    width: 100%;
    // padding-left: 40px;
    padding-left: 1vw;
    .list-1 {
      @include flex(column, null, flex-start);
      width: 100%;
    }
    .list-2 {
      @include flex(column, null, flex-start);
      margin-top: 22%;
    }
    .list-3,
    .list-4 {
      @include flex(column, null, flex-start);
      margin: 22% 0;
    }

    .list {
      @include flex(row, null, flex-start);
      padding: 15px 30px 15px 20px;
      cursor: pointer;
      min-width: 50%;
      .side-icon {
        width: 1rem;
        height: 1rem;
        margin-right: 1.8rem;
      }
      .menu-text {
        padding-top: 5px;
        margin: 0;
        font-size: 0.9rem;
        font-weight: 700;

        @media screen and (max-width: 1200px) {
          font-size: 0.7rem;
        }
        &.logout {
          font-weight: normal;
        }
      }
    }
    .active-list {
      @include flex(row, null, flex-start);
      background-color: #f8fbff;
      border-radius: 5px;
      // padding: 10px 30px 10px 20px;
      .active-text {
        color: #0d3dde;
        padding-top: 5px;
        margin: 0;
        font-size: 0.9rem;
        font-weight: 700;

        @media screen and (max-width: 1200px) {
          font-size: 0.7rem;
        }
      }
    }
  }
  .log-out {
    position: absolute;
    bottom: 0;
    @include flex(row, null, center);
    // margin: 0 10% 1rem 10%;
    padding: 20px 0 20px 60px;
    cursor: pointer;
    #logout {
      width: 1.4rem;
      height: 1.4rem;
      margin-right: 1.4rem;
    }
    #logout-text {
      font-size: 20px;
      font-weight: normal;
      color: #747070;
    }
  }
}
