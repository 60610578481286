.signup {
  position: relative;
  @include flex(column, nill, nill);
  width: 100%;
  height: 100%;
  overflow: hidden;
  // background-color: pink;
  @include screen("inbetween") {
    // height: 100%;
    min-height: 85vh;
    overflow-y: auto;
    // border: 1px solid blue;
  }
  .section {
    @include flex(row, nill, nill);
    min-height: 85vh;
    // height: calc(100% - 7.187rem);
    // height: 100%;
    position: relative;
    @include screen("inbetween") {
      // @include flex(column, nill, nill);
      min-height: 100vh;
    }
    .poster-box {
      width: 50%;
      // min-height: 85vh;
      position: relative;
      @include screen("inbetween") {
        display: none;
        width: 100%;
        order: 2;
        height: auto;
        min-height: 0;
        margin-top: 2.5rem;
      }
      #poster {
        width: 100%;
        height: 100%;
        // border: 1px solid yellow;
      }
    }
    .post-cont {
      min-height: 85vh;
      width: 50%;
      position: relative;
      @include screen("inbetween") {
        display: none;
      }
      #poster {
        width: 100%;
        height: 100%;
        // border: 1px solid pink;
      }
    }
    .form-box {
      width: 50%;
      @include screen("inbetween") {
        width: 100%;
        // height: auto;
        order: 1;
        min-height: 85vh;
      }
      .icon-box {
        width: 100%;
        margin-top: 5rem;
        @include flex(row, center, center);
        height: 4.125rem;
        @include screen("inbetween") {
          height: 2.5rem;
          margin: 2rem 0;
          img {
            height: 100%;
          }
        }
        img {
          width: 4.5rem;
        }
      }
      &.form-cont {
        @include screen("inbetween") {
          min-height: auto;
        }
      }
    }
    form {
      width: 70%;
      height: 100%;
      margin-left: 10%;
      margin-top: 10%;
      // margin: 0 6rem 0 5rem;
      padding: 0.5rem 0 6.5rem 0;
      // border: 1px solid green;
      // background-color: yellow;
      @include screen("inbetween") {
        width: 90%;
        margin-left: 0;
        padding: 1rem;
      }
      
      h1 {
        color: #010b1a;
        font-size: 3.2rem;
        line-height: 4.183rem;
        margin: 0;
        font-weight: bold;
        @include screen("inbetween") {
          font-size: 1.87rem;
          line-height: 2.5rem;
        }
      }
      h3 {
        color: #010b1a;
        font-size: 2rem;
        line-height: 2.9rem;
        margin: 0;
        font-weight: bold;
        @include screen("inbetween") {
          font-size: 1.87rem;
          line-height: 2.5rem;
        }
      }
      #success {
        color: #23ba45;
      }
      h5 {
        font-size: 1.1rem;
        font-weight: normal;
        // color: #010b1a;
        color: #4e4e50;
        line-height: 2rem;
        // margin-right: 2rem;
        width: 90%;
        // border: 1px solid red;
        @include screen("inbetween") {
          font-size: 0.95rem;
          line-height: 1.2rem;
          width: 90%;
        }
      }
      
      #summary {
        font-size: 0.9rem;
        //   color: #010b1a;
        color: #7a7a7e;
        line-height: 1.5rem;
        @include screen("inbetween") {
          font-size: 0.75rem;
          line-height: 1rem;
        }
      }
      #paragraph {
        margin-right: 5rem;
        font-size: 0.9rem;
        color: #7a7a7e;
        line-height: 1.5rem;
        @include screen("inbetween") {
          font-size: 0.75rem;
          line-height: 1rem;
        }
      }
      .doc-box {
        @include flex(row, space-between, center);
        width: 100%;
        margin-top: 2rem;
        //  border: 1px solid red;
        @include screen("inbetween") {
          @include flex(column, center, center);
        }
      }
      #doc-cont {
        @include flex(row, center, center);
        width: 100%;
        margin-top: 2rem;
      }
      .btn-col-box {
        @include flex(column, nill, nill);
        margin-top: 3.5rem;
        @include screen("inbetween") {
          @include flex(column, center, center);
          margin-top: 2rem;
        }
      }
      .btn-row-box {
        @include flex(row, nill, nill);
        margin-top: 3.5rem;
        width: 65%;
        height: 3rem;
        @include screen("inbetween") {
          height: 2.8rem;
          width: 100%;
        }
      }
      #small-btn {
        margin-top: 8rem;
      }
    }
    &.welcome {
      @include screen("inbetween") {
        min-height: 85vh;
        height: auto;
      }
    }

    .redirect-link{
      display: inline-block;
      color: #0d3dde;
      font-size: .9rem;
      text-decoration: none;
      padding-top: 15px;
    }
  }
}
