.editable-field{
    label {
        display: block;
        margin-bottom: 1rem;
        font-size: 0.9rem;
        font-weight: 800;
        color: #423b3b;
    }
    
    .edit-input{
        border: 1.5px solid #423b3b40;
        padding: 4px 8px;
        height: 2.5rem;      
        border-radius: 5px;
        font-size: .9rem;

        &.small {
            max-width: 120px;
        }
    }
    .name {
        display: block;
        font-size: 1rem;
        color: #423b3b;
        opacity: 1;
        text-decoration: none;
        word-break: break-all;
        cursor: pointer;
    }
}