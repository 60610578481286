.new-request {
	@include flex(column, nill, nill);
	// padding: 1rem 0;
	.search-panel {
		border: 1px solid #d3d1d1;
		@include flex(row, nill, center);
		width: 30%;
		// height: 2.5rem;
		padding: 1rem 1% 1rem 3%;
		box-sizing: border-box;
		border-radius: 8px;
		margin-bottom: 2.5rem;
		font-weight: 300;
		opacity: 1;
		#search-icon {
			width: 1rem;
			height: 1rem;
			margin-right: 0;
			opacity: 0.8;
		}
		input::placeholder {
			font-size: 1rem;
			font-weight: 300;
			opacity: 1;
		}
	}
}

.admin-table {
	box-sizing: border-box;
	overflow-y: scroll;
	scrollbar-color: #E4E4E4 transparent;
	max-height: 50vh;
	margin-top: 10px;
	border-spacing: 0;
	width: 100%;
	.user-cont{
		display: flex;
		align-items: center;

		.img-layout{
			width: 35px;
			height: 35px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			text-align: center;
			margin-right: 10px;
			color: #fff;
			&.pending{
				background-color: #ffc144;
			}
			&.verified{
				background-color: #23baa6;
			}
			&.revoked{
				background-color: #fe753e;
			}
			&.rejected{
				background-color: #423b3b;
			}
		}
		img {
			width: 35px;
			height: 35px;
			border-radius: 50%;
			margin-right: 10px;			
		}
		p{
			text-transform: capitalize;
		}
	}
	thead{
		text-align: left;
		th{
			font-weight: bold;
			font-size: 0.9rem;
			color: #423B3B;
		}
	}
	tbody{
		font-weight: 400;
		font-size: .9rem;
	}
	tr{
		cursor: pointer;
	}
	td{
		padding: 15px 0;
		padding-right: 5px;
		border-bottom: 1px solid #d3d1d1;
		text-transform: capitalize;
		font-size: 0.85rem;
		.status{
			text-align: center;
			height: 10px;
			padding: 10px 20px;
			border-radius: 20px;
			font-size: 0.8rem;
			font-weight: 500;
			color: #fff;
			text-transform: capitalize;
			&.pending{
				background-color: #ffc144;
			}
			&.verified{
				background-color: #23baa6;
			}
			&.revoked{
				background-color: #fe753e;
			}
			&.rejected{
				background-color: #423b3b;
			}
		}	
	}
}

// ******** Pagination ********
.pagination {
	margin-top: 4rem;
	@include flex(row, flex-start, center);
	width: 100%;
	// border: 1px solid red;
	.box {
		@include flex(row, space-evenly, center);
		width: 6%;
		height: 1.5rem;
		border: 1.5px solid #d3d1d1;
		box-shadow: 1px 1px 1px rgba(0,0,0,.1);
		background-color: #fff;
		border-radius: 6px;
		cursor: pointer;
		&:disabled{
			cursor: not-allowed;
		}
		#arrow {
			width: 0.6rem;
			height: auto;
		}
		span {
			font-size: 0.6rem;
			color: #707070;
		}
	}
	#text {
		margin: 0 3rem;
		font-size: 0.8rem;
		color: #707070;
	}
}


// .toaser-body{
// 	color: #23baa6;
// 	margin: 10px auto;
// 	text-align: center;
// 	display: flex;
// 	justify-content: center;
// 	font-size: 0.9rem;
// }