@import "./mixins.scss";
@import "./screens.scss";


.modal-cont {
  height: 100%;

  .modal-header{
    margin-top: 100px;
    height: 200px;
    text-align: center;


    h2{
      font-size: 1.1rem;
      font-weight: 700;
    }
    p{
      font-size: .9rem;
    }
  }
  .modal-actions{
    display: flex;
    width: 100%;
    justify-content: space-between;

    button {
      color: #fff;
    }
    
  }
}

