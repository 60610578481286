
.new-request-data{
    margin-top: 10px;
    display: flex;

    .left {
        .card-1 {
            font-size: .8rem;
            width: 40%;
        }
        .card-2{
            font-size: .8rem;
            width: 40%;
        }
        .card-3{
            font-size: .8rem;
            width: 20%;
        }
    }
}