@import "./mixins.scss";

.big-img{
    cursor: pointer;
    width: 100%;
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
#img-popup{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.3);
    @include flex(row, center, center);
    
    
    
    .img-cont{
        width: 500px;
        height: 900px;
        @include flex(row, center, center);
        position: relative;

        // .close{
        //     color: #fff;
        //     font-size: 1rem;
        //     font-weight: bold;
        //     cursor: pointer;
        //     background: #000;
        //     border: none;
        //     padding: 10px 15px;
        //     border-radius: 50%;
        //     position: absolute;
        //     top: 25%;
        //     right: 0;
        // }

        .popup-img{
            max-height: 400px;
            max-width: 400px;
            cursor: default;
        }
    }
}