@import "./mixins.scss";
@import "./screens.scss";

.form-input {
	@include flex(column, nill, nill);
	width: 100%;
	height: 4.3rem;
	box-sizing: border-box;
	margin-top: 1rem;
	&.with-label{
		// height: 5rem;
		height: 6rem;
	}
	&.admin-form {
		width: 30%;
	}
	&.ads-form{
		width: 45%;
	}
}
#margin-sides{
	margin: 0 3%;
}
#label {
	margin-bottom: 1rem;
	font-size: 0.9rem;
	font-weight: 800;
	color: #423b3b;
}
.input-box {
	width: 100%;
	height: 100%;
	border-radius: 10px;
	overflow: hidden;
	border: 1.5px solid #423b3b40;
	
	&:hover {
		border: 1px solid #0d3dde;
	}
	.left,
	.right {
		width: 46%;
		height: 100%;
	}
	#vertical {
		height: 100%;
		border-left: 1px solid #d3d1d1;
		margin: 0 1rem;
	}
}

input {
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
	color: #423B3B;
	font-size: 1rem;
	box-sizing: border-box;
	font-weight: normal;
	padding: 0 1.4rem;
	&::placeholder {
		font-size: 1rem;
		color: #423B3B;
		font-weight: unset;
	}
	&:focus {
		border: none;
		outline: none;
	}
	&.search {
		width: 90%;
	}
}
#short {
	width: 40%;
}
.form-errors {
	width: 100%;
	display: block;
	@include flex(row, nill, nill);
	p {
		margin: 0.5rem 0 0 0;
		// margin: 0.5rem 0 1rem 0;
		font-size: 0.7rem;
	}
	#orange-error {
		font-size: .9rem;
		margin-top: .9rem;
		margin-right: 0.5rem;
		color: #f3661f;
	}
	#blue-error {
		font-size: .9rem;
		margin-top: .9rem;
		color: #0d3dde;
		cursor: pointer;
	}
}
.hide-err {
	display: none;
}
