.header {
	@include flex(row, space-between, center);
	width: 100%;
	padding-left: 5%;
	.box {
		@include flex(row, nill, center);
		width: 25%;
		.img-box {
			width: 3.2rem;
			height: 3.5rem;
			@include flex(column, center, center);
			border-radius: 15px;
			margin-right: .6rem;
			svg {
				width: 55%;
				height: 55%;
			}
		}
		#yellow {
			background-color: #ffc144;
		}
		#green {
			background-color: #23baa6;
		}
		#red {
			background-color: #fe753e;
		}
		#black {
			background-color: #423b3b;
		}
		
		.details {
			@include flex(column, space-between, nill);
			#title {
				margin: 0 0 5px 0;
				color: #707070;
				font-size: 0.9rem;
			}
			#count {
				margin: 0 0 0 0;
				color: #010b1a;
				font-size: 1.5rem;
				font-weight: 700;
			}
		}
	}
}
