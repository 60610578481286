.create-nft{
    @include flex(column, nill, nill);
    h2 {
		margin: 0 0 .5rem 0;
        font-size: 1.4rem;
	}
    .instructions{
        font-size: 14px;
        color: #423b3b
    }
    .nft-cont{
        width: 100%;
        gap: 10px;
        @include flex(row, space-between, flex-start);
        flex-wrap: wrap;

        .nft-item{
            width: 20%;
            min-width: 160px;
            max-width: 200px;

            .count{
                font-weight: 600;
                font-size: 1.2rem;
                margin: 30px 0;
            }
            .img-cont{
                width: 100%;
                height: 200px;
                background: #f8fbff;
                position: relative;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                
                margin: 30px 0;

                @include flex(row, center, center);

                .upload__image-wrapper{
                    border: none;
                    cursor: pointer;
                    font-size: 1rem;
                    font-weight: 400;
                    min-width: 160px;
                    padding: 13px 8px;
                    border-radius: 2px;
                    letter-spacing: 1px;
                    background: rgba(241, 241, 241, 0.6);
                    
                }
            }
            form {
                width: 100%;

                .form-row{
                    min-height: 6rem;
                }
                .form-input{
                    margin: 40px 0;
                }
            }
        }
    }
    .buttons-cont {
		padding-top: 6rem;
		width: 70%;
		@include flex(row, flex-start, null);
		gap: 70px;

		.btn-text {
			color: white;
			font-weight: 600;
		}

		.verify {
			@extend .btn-text;
			background-color: #23baa6;
		}
		.reverify {
			@extend .btn-text;
			background-color: #23baa6;
		}
		.reject {
			@extend .btn-text;
			background-color: #fe753e;
		}
		.save {
			@extend .btn-text;
			background-color: #0d3dde;
		}
	}

}