.admin-layout {
	background-color: #f8fbff;
	width: 100%;
	min-height: 100vh;
	
	@include flex(row, space-between, null);
	position: relative;
	.sidebar-cont {
		min-width: 22%;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
		overflow-y: scroll;
		scrollbar-color: #fff #fff;
		overflow-y: hidden;
		

		&::-webkit-scrollbar {
			width: 7px;
		}

		 
		&::-webkit-scrollbar-track {
			border-radius: 10px;
		}
		 
		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background: #E4E4E4;
		}

		&:hover{
			scrollbar-color: #E4E4E4 #fff;
			overflow-y: scroll;
		}
	}
	.body {
		margin-left: 22%;
		background-color: #f8fbff;
		width: 100%;
		padding: 1rem 2rem 0 2.5rem;

		
		.header-cont {
			height: 14vh;
			width: 100%;
			margin: .7rem 0;
			@include flex(row, nill, center);
			background-color: #ffffff;
			border-radius: 5px;
		}
		.content-cont {
			min-height: 80vh;
			border-radius: 5px;
			background-color: #ffffff;
			box-sizing: border-box;
			padding: 3rem 4% 7rem 4%;
		}
	}
}

#title{
	margin-bottom: 2rem;
}
