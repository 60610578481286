.ads{
    @include flex(column, nill, nill);
    h2 {
		margin: 0 0 2.5rem 0;
        font-size: 1.4rem;
	}
    .fields {
		@include flex(row, space-between, flex-start);
		flex-wrap: wrap;
		width: 100%;
        margin-bottom: 4rem;
		// background-color: pink;
        .image-cont{
            width: 45%;
            // border: 1px solid red;
            p{
                margin: 0 0 1.5rem 0;
                font-size: 0.9rem;
                font-weight: 800;
                color: #423b3b;
            }
            .details{
                @include flex(row, nill, center);
                cursor: pointer;
            }
            img{
                width: 1.5rem;
                height: 2.5rem;
                margin-right: 1rem;
            }
            span{
                font-size: 0.6rem;
                color: #423b3b;
            }
        }
        .input-wrapper{
            width: 45%;
            height: 4.4rem;
            position: relative;
        
            span {
                position: absolute;
                margin-left: auto;
                margin-right: auto;
                top: 101%;
                left: 3.5rem;
                bottom: 100%;
                text-align: center;
                opacity: .7;
            }
          }

        .textarea-box{
            @include flex(column, nill, nill);
            width: 45%;
            margin-top: 2rem;
            
            label{
                margin-bottom: 0.5rem;
                font-size: 0.9rem;
                font-weight: 800;
                color: #423b3b;
            }
            textarea{
                border: 1px solid #d3d1d1;
	            border-radius: 10px;
                padding: 0.5rem 1.4rem;
                outline: none;
                color: #423B3B;
                font-size: 1rem;
                font-weight: normal;
                resize: none;
                font-family: inherit;
                &:hover {
                    border: 1px solid #0d3dde;
                }
            }
        }
	}
}