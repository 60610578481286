@import "./mixins.scss";
@import "./screens.scss";
@import "./button.scss";
@import "./loader.scss";
@import "./input.scss";
@import "./footer.scss";
@import "./modal.scss";
@import "./admin.scss";
@import "./auth.scss";
@import "./sidebar.scss";
@import "./header.scss";
@import "./request.scss";
@import "./request-detail.scss";
@import "./verification.scss";
@import "./create-admin.scss";
@import "./advert.scss";
@import "./filter.scss";
@import "./createAds.scss";
@import "./create-nft.scss";
@import "./img-popup.scss";
@import "./editable-field.scss";

body {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "Rubik", sans-serif;

	&::-webkit-scrollbar {
		width: 10px;
	}
	 
	&::-webkit-scrollbar-track {
		border-radius: 10px;
	}
	 
	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: #969696;
	}
}

.webcam-container {
	width: 100%;
	height: 100%;

	.webcam {
		width: 100%;
	}

	.webcam-btn {
		margin-top: 1rem;
		@include flex(row, space-between, center);
	}
}

.selfie-box {
	width: 100%;
	height: 70%;
}
