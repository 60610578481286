.create {
	h2 {
		margin: 0 0 2.5rem 0;
		font-size: 1.4rem;
	}
	.fields {
		@include flex(row, space-between, flex-start);
		flex-wrap: wrap;
		width: 100%;
		margin-top: 2rem;	
		
		.form-input{
			height: 6rem;
		}
	}
	.form-errors {
		margin-top: 0;
		margin-bottom: 1rem;
	}
}
